import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import renderHTML from "react-render-html";
import "./HtmlSection.scss";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection(props) {
  const { data } = props;
  const classes = useStyles();
  return (
    <div className="html-section">
      <div style={{marginBottom:"8px"}}>
        <div className={classes.description}>{renderHTML(data)}</div>
      </div>
    </div>
  );
}
